import { http } from '../core';

class MediaUploadService {
  async upload(file: File) {
    try {
      const formData = new FormData();
      formData.append('media', file);
      formData.append('content_type', file.type);

      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: '/outbound-media/',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data; // This will return the response from the API
    } catch (e) {
      console.error('Media upload failed', e);
      return null;
    }
  }
}

const mediaUploadService = new MediaUploadService();
export default mediaUploadService;
