import { http } from '../core';

class LeadViewedVehiclesService {
  async list(clientId: number | string, leadId: number | string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/lead-vehicles/?client_id=${clientId}&lead_id=${leadId}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const leadViewedVehiclesService = new LeadViewedVehiclesService();
export default leadViewedVehiclesService;
