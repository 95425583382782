import React, { useContext, useState } from "react";
import { LeadContext } from "../context/LeadContext";
import { AppContext } from "../context/AppContext";
import TToggleItem from "./TToggleItem";
import { useDarkTheme } from "../hooks";
import TButtonItem from "./TButtonItem";
import TExpandableItemGroup from "./TExpandableItemGroup";
import {
  IonButton,
  IonCard,
  IonChip,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonItem,
  IonText,
  IonAlert,
} from "@ionic/react";
import { NavContext } from "@ionic/react";
import { appNotification, loadingIndicator, util } from "../core";
import { ChatMessageBoxContext } from "../context/ChatMessageBoxContext";
import {
  creditApplicationsService,
  routeOneCreditApplicationService,
} from "../services";
import {
  sendOutline,
  open as openIcon,
  arrowUpCircleOutline,
} from "ionicons/icons";
import moment from "moment";
import "../styles/LeadCredit.css";

// RouteOne SVG Logo
const RouteOneLogo = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    className='routeoneSvg'
    viewBox='0 0 704 132'
  >
    <g>
      <g>
        <g>
          <path
            className='st0'
            d='M195.3,70.9c6.6-2.4,10.4-8,10.4-15.6c0-10.6-7-17-18.8-17h-17.7c-0.7,0-1.2,0.5-1.2,1.2v53.8     c0,0.7,0.5,1.2,1.2,1.2h7.2c0.7,0,1.2-0.5,1.2-1.2v-21h7.7l15,21.7c0.2,0.3,0.6,0.5,1,0.5h8.3c0.5,0,0.9-0.3,1.1-0.7     c0.2-0.4,0.2-0.9-0.1-1.3L195.3,70.9z M177.5,46.7h9.4c7.7,0,9.3,4.6,9.3,8.6s-1.6,8.6-9.3,8.6h-9.4V46.7z'
          />
          <path
            className='st0'
            d='M253.9,36.8c-17.3,0-29.8,12.5-29.8,29.6S236.6,96,253.9,96c17.2,0,29.7-12.5,29.7-29.6     S271.1,36.8,253.9,36.8z M253.9,87.6c-11.7,0-19.9-8.7-19.9-21.2s8.2-21.2,19.9-21.2c11.7,0,19.8,8.7,19.8,21.2     C273.7,78.7,265.4,87.6,253.9,87.6z'
          />
          <path
            className='st0'
            d='M352.4,38.3h-7c-0.7,0-1.2,0.5-1.2,1.2v29c0,12.5-5,18.8-14.9,18.8s-14.9-6.3-14.9-18.8v-29     c0-0.7-0.5-1.2-1.2-1.2h-7.3c-0.7,0-1.2,0.5-1.2,1.2v28.9c0,17.5,8.9,27.6,24.5,27.6s24.5-10,24.5-27.6V39.5     C353.6,38.9,353,38.3,352.4,38.3z'
          />
          <path
            className='st0'
            d='M418.7,38.3h-45.3c-0.7,0-1.2,0.5-1.2,1.2v6c0,0.7,0.5,1.2,1.2,1.2h17.9v46.5c0,0.7,0.5,1.2,1.2,1.2h7.2     c0.7,0,1.2-0.5,1.2-1.2V46.7h17.9c0.7,0,1.2-0.5,1.2-1.2v-6C419.9,38.9,419.3,38.3,418.7,38.3z'
          />
          <path
            className='st0'
            d='M472.3,38.3h-32.5c-0.7,0-1.2,0.5-1.2,1.2v53.8c0,0.7,0.5,1.2,1.2,1.2h32.5c0.7,0,1.2-0.5,1.2-1.2v-6     c0-0.7-0.5-1.2-1.2-1.2h-24.1v-17h19.7c0.7,0,1.2-0.5,1.2-1.2v-6c0-0.7-0.5-1.2-1.2-1.2h-19.7v-14h24.1c0.7,0,1.2-0.5,1.2-1.2v-6     C473.5,38.9,472.9,38.3,472.3,38.3z'
          />
          <path
            className='st1'
            d='M524.3,36.8c-17.3,0-29.8,12.5-29.8,29.6S507.1,96,524.3,96c17.2,0,29.7-12.5,29.7-29.6     S541.5,36.8,524.3,36.8z M524.3,87.6c-11.7,0-19.9-8.7-19.9-21.2s8.2-21.2,19.9-21.2s19.8,8.7,19.8,21.2     C544.2,78.7,535.8,87.6,524.3,87.6z'
          />
          <path
            className='st1'
            d='M618.9,38.3h-6.5c-0.7,0-1.2,0.5-1.2,1.2v36.6l-29.8-37.3c-0.2-0.3-0.6-0.5-0.9-0.5h-4.1     c-0.7,0-1.2,0.5-1.2,1.2v53.8c0,0.7,0.5,1.2,1.2,1.2h6.5c0.7,0,1.2-0.5,1.2-1.2V56.7L613.8,94c0.2,0.3,0.6,0.5,0.9,0.5h4.1     c0.7,0,1.2-0.5,1.2-1.2V39.5C620.1,38.9,619.5,38.3,618.9,38.3z'
          />
          <path
            className='st1'
            d='M677.4,46.7c0.7,0,1.2-0.5,1.2-1.2v-6c0-0.7-0.5-1.2-1.2-1.2h-32.5c-0.7,0-1.2,0.5-1.2,1.2v53.8     c0,0.7,0.5,1.2,1.2,1.2h32.5c0.7,0,1.2-0.5,1.2-1.2v-6c0-0.7-0.5-1.2-1.2-1.2h-24.1v-17H673c0.7,0,1.2-0.5,1.2-1.2v-6     c0-0.7-0.5-1.2-1.2-1.2h-19.7v-14H677.4z'
          />
        </g>
        <g>
          <path
            className='st1'
            d='M121.4,40.5c-1-1.7-2.6-1.3-3.2-1C92.1,51,59.4,74.6,47.3,122.3c-0.1,0.9-0.1,3,2.7,3.7     c5.1,1.3,10.3,2,15.5,2c33.9,0,61.6-27.6,61.6-61.6C127.2,57.4,125.2,48.7,121.4,40.5z'
          />
          <path
            className='st0'
            d='M115.3,30.1c-11.6-15.9-30-25.3-49.7-25.3C31.7,4.9,4.1,32.5,4.1,66.4c0,12.3,3.6,24,10.4,34.2     c0.5,0.7,2.1,2.5,4-0.2l0,0c10.8-18.5,39.6-57.1,95.7-67.6l0,0C116.4,32.4,115.7,30.8,115.3,30.1z'
          />
        </g>
      </g>
    </g>
    <path
      className='st1'
      d='M692.9,85.4c2.8,0,5.1,2.3,5.1,5.2c0,2.9-2.3,5.2-5.1,5.2c-2.8,0-5.1-2.3-5.1-5.2  C687.8,87.7,690.1,85.4,692.9,85.4L692.9,85.4z M692.9,86.2c-2.3,0-4.1,2-4.1,4.4c0,2.5,1.9,4.4,4.2,4.4c2.3,0,4.1-1.9,4.1-4.4  C697.1,88.2,695.2,86.2,692.9,86.2L692.9,86.2z M691.9,93.6H691v-5.8c0.5-0.1,0.9-0.1,1.6-0.1c0.9,0,1.4,0.2,1.8,0.4  c0.3,0.3,0.5,0.6,0.5,1.2c0,0.8-0.5,1.2-1.1,1.4v0c0.5,0.1,0.8,0.6,1,1.4c0.1,0.9,0.3,1.2,0.4,1.4h-1c-0.1-0.2-0.3-0.7-0.4-1.5  c-0.1-0.7-0.5-1-1.2-1h-0.6V93.6z M691.9,90.4h0.7c0.8,0,1.4-0.3,1.4-1c0-0.5-0.4-1-1.4-1c-0.3,0-0.5,0-0.7,0V90.4z'
    />
  </svg>
);

const LeadCredit: React.FC = () => {
  const { state, updateAndSaveLead } = useContext(LeadContext);
  const { state: appState } = useContext(AppContext);
  const { user } = appState;
  const { lead } = state;
  const creditApps = lead?.credit_applications || [];
  const count = creditApps.length;
  const badgeValue = count ? count : lead?.no_credit_app_needed ? 'N/A' : 0;
  const badgeColor = badgeValue === 'N/A' ? 'warning' : undefined;
  const isDarkTheme = useDarkTheme();
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const { navigate } = useContext(NavContext);

  const append = (c: string) => {
    appendMessage(c);
    util.hideLeadSidebar();
  };

  // RouteOne submission states
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState<string | null>(null);

  // Co-Buyer navigation confirmation state
  const [showCoBuyerNavConfirm, setShowCoBuyerNavConfirm] = useState(false);
  const [coBuyerLead, setCoBuyerLead] = useState<any>(null);

  const handleSendToRouteOne = (appId: string) => {
    setSelectedAppId(appId);
    setShowConfirm(true);
  };

  const confirmSendToRouteOne = async () => {
    if (!selectedAppId) return;
    try {
      await loadingIndicator.create(); // Show loading indicator
      const response =
        await routeOneCreditApplicationService.create(selectedAppId);
      console.log("RouteOne response:", response);
      // Update `routeone_deal_jacket.created_at` field for the selected credit app
      const updatedApp = creditApps.find(
        (app: any) => app.id === selectedAppId
      );
      if (updatedApp) {
        if (!updatedApp.routeone_deal_jacket) {
          updatedApp.routeone_deal_jacket = {};
        }
        updatedApp.routeone_deal_jacket.routeone_credit_applications = [
          {
            created_at: new Date().toISOString(),
          },
        ];
      }

      // Show success notification
      appNotification.toast(
        "Credit application successfully sent to RouteOne!",
        "Success"
      );
      // Reset state
      setSelectedAppId(null);
      setShowConfirm(false);
    } catch (error) {
      console.error("Error sending credit app to RouteOne:", error);
      // Show success notification
      appNotification.toast(
        "Failed to send credit application to RouteOne. Please try again.",
        "Error"
      );
    } finally {
      await loadingIndicator.dismiss(); // Hide loading indicator
    }
  };

  let alertHeader = "Send to RouteOne";
  let alertMessage =
    "Are you sure you want to send this credit application to RouteOne?";

  if (selectedAppId) {
    const selectedApp = creditApps.find((app: any) => app.id === selectedAppId);
    if (
      selectedApp?.routeone_deal_jacket?.routeone_credit_applications?.length
    ) {
      const submissions =
        selectedApp.routeone_deal_jacket.routeone_credit_applications;
      const latestSubmission = submissions[submissions.length - 1];
      const submittedAt = moment(latestSubmission.created_at).format(
        "MMMM Do YYYY, h:mm a"
      );
      alertMessage = `This credit application has already been submitted to RouteOne on ${submittedAt}. Are you sure you'd like to resubmit it?`;
      alertHeader = "Resubmit to RouteOne?";
    }
  }

  const handleCoBuyerClick = (cobuyer_lead: any) => {
    setCoBuyerLead(cobuyer_lead);
    setShowCoBuyerNavConfirm(true);
  };

  const confirmCoBuyerNav = () => {
    if (coBuyerLead) {
      navigate(
        `/text-messages/conversation/${coBuyerLead.client}/${coBuyerLead.id}/`,
        "none"
      );
    }
    setShowCoBuyerNavConfirm(false);
    setCoBuyerLead(null);
  };

  return (
    <>
      <TExpandableItemGroup
        title='Credit'
        badge={badgeValue}
        badgeColor={badgeColor}
      >
        {!count && (
          <TToggleItem
            label='No Credit App Needed'
            checked={!!lead?.no_credit_app_needed}
            onIonChange={(e: any) => {
              const value = e.detail.checked ? new Date().toISOString() : null;
              const ce = new CustomEvent('', { detail: { value } });
              updateAndSaveLead('no_credit_app_needed', ce);
            }}
            wrapLabel
          />
        )}
        {!creditApps?.length && (
          <IonItem>
            <span className='detail'>No Credit Applications</span>
          </IonItem>
        )}
        {creditApps?.map((app: any) => {
          // Ensure the primary applicant is displayed first
          const primaryApplicant = app.applicants.find(
            (applicant: any) => applicant.is_primary
          );

          return (
            <IonCard
              key={app.id}
              className='credit-card'
              onClick={() =>
                creditApplicationsService.openApplicationPDF(app.id)
              }
            >
              <IonCardHeader>
                <IonCardTitle>
                  <>
                    {primaryApplicant
                      ? `${primaryApplicant.first_name} ${primaryApplicant.last_name}`
                      : app.lead.full_name}
                    <IonIcon className='open-full-credit-app' icon={openIcon} />
                  </>
                </IonCardTitle>
                <IonCardSubtitle>
                  Co-Buyer:{" "}
                  {app.cobuyer_lead ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCoBuyerClick(app.cobuyer_lead);
                      }}
                    >
                      {app.cobuyer_lead.full_name}{" "}
                      {(() => {
                        const coBuyerApplicant = app.applicants.find(
                          (applicant: any) => applicant.is_primary === false
                        );

                        let statusLabel = "";
                        let color: "primary" | "success" | "warning" =
                          "primary";

                        if (!coBuyerApplicant) {
                          // Co-buyer lead exists, but no co-buyer applicant yet
                          statusLabel = "Not started";
                          color = "warning";
                        } else if (!coBuyerApplicant.terms_agreed) {
                          // Co-buyer applicant found, but terms not agreed
                          statusLabel = "In progress";
                          color = "warning";
                        } else {
                          // Co-buyer applicant exists and terms are agreed
                          statusLabel = "Complete";
                          color = "success";
                        }

                        return <IonChip color={color}>{statusLabel}</IonChip>;
                      })()}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                <IonText>
                  <p>
                    <strong>Application Type:</strong>{" "}
                    {app.is_simple ? "Simple" : "Full"}
                  </p>
                  <p>
                    <strong>Created:</strong>{" "}
                    {moment(app.created_at).format("MMMM Do YYYY, h:mm a")}
                  </p>
                  <p>
                    <strong>Last Updated:</strong>{" "}
                    {moment(app.updated_at).fromNow()}
                  </p>
                  {app.routeone_deal_jacket?.routeone_credit_applications?.map(
                    (creditApp: any, index: number) => (
                      <p key={`routeone-creditapp-sent-${index}`}>
                        <strong>Sent to RouteOne:</strong>{" "}
                        {moment(creditApp.created_at).fromNow()}
                      </p>
                    )
                  )}
                </IonText>

                {/* RouteOne Button */}
                {!!lead?.client_route_one_dealership_id &&
                  (user.is_admin || user.is_client_admin) && (
                    <div className='routeone-button-container'>
                      <IonButton
                        title='Send to RouteOne'
                        color={isDarkTheme ? "dark" : "light"}
                        size='small'
                        expand='block'
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click event
                          handleSendToRouteOne(app.id);
                        }}
                      >
                        <RouteOneLogo />
                        <IonIcon icon={sendOutline} slot='end' />
                      </IonButton>
                    </div>
                  )}
              </IonCardContent>
            </IonCard>
          );
        })}
        <TButtonItem
          color='secondary'
          onClick={async () => {
            try {
              await loadingIndicator.create();
              const simpleCreditAppUrl = `https://ca.tecobi.com/creditapp/short/${lead?.client}/${lead?.id}/`;
              const message = `Here is our application: ${simpleCreditAppUrl} Your PIN code is: ${lead?.pin_code}.`;
              append(message);
            } finally {
              loadingIndicator.dismiss();
            }
          }}
        >
          <IonIcon icon={arrowUpCircleOutline} slot='start' />
          Short Credit App
        </TButtonItem>
        <TButtonItem
          color='secondary'
          onClick={async () => {
            try {
              await loadingIndicator.create();
              const fullCreditAppUrl = `https://ca.tecobi.com/creditapp/${lead?.client}/${lead?.id}/`;
              const message = `Here is our application: ${fullCreditAppUrl} Your PIN code is: ${lead?.pin_code}.`;
              append(message);
            } finally {
              loadingIndicator.dismiss();
            }
          }}
        >
          <IonIcon icon={arrowUpCircleOutline} slot='start' />
          Full Credit App
        </TButtonItem>
      </TExpandableItemGroup>

      {/* Confirmation Alert for RouteOne Submission */}
      <IonAlert
        isOpen={showConfirm}
        header={alertHeader}
        message={alertMessage}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Send to RouteOne canceled");
              setShowConfirm(false);
            },
          },
          {
            text: "Confirm",
            role: "confirm",
            handler: confirmSendToRouteOne,
          },
        ]}
        onDidDismiss={() => setShowConfirm(false)}
      />

      {/* Confirmation Alert for navigating to co-buyer lead page */}
      <IonAlert
        isOpen={showCoBuyerNavConfirm}
        header="Navigate to Co-Buyer's Lead?"
        message={`Do you want to go to ${coBuyerLead?.full_name}'s lead page?`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setShowCoBuyerNavConfirm(false);
              setCoBuyerLead(null);
            },
          },
          {
            text: "Yes, navigate",
            role: "confirm",
            handler: confirmCoBuyerNav,
          },
        ]}
        onDidDismiss={() => setShowCoBuyerNavConfirm(false)}
      />
    </>
  );
};

export default LeadCredit;
