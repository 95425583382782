import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';
import moment from 'moment';
import 'moment-timezone';

class ClientHoursService extends CachedService {
  constructor() {
    super(
      'client-hours',
      'clientHours',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.HOUR
    );
  }

  hoursChoices: {value: string | null, text: string}[] = [
    {
      "value": null,
      "text": "Closed"
    },
    {
      "value": "00:00:00",
      "text": "12:00 am"
    },
    {
      "value": "00:30:00",
      "text": "12:30 am"
    },
    {
      "value": "01:00:00",
      "text": "1:00 am"
    },
    {
      "value": "01:30:00",
      "text": "1:30 am"
    },
    {
      "value": "02:00:00",
      "text": "2:00 am"
    },
    {
      "value": "02:30:00",
      "text": "2:30 am"
    },
    {
      "value": "03:00:00",
      "text": "3:00 am"
    },
    {
      "value": "03:30:00",
      "text": "3:30 am"
    },
    {
      "value": "04:00:00",
      "text": "4:00 am"
    },
    {
      "value": "04:30:00",
      "text": "4:30 am"
    },
    {
      "value": "05:00:00",
      "text": "5:00 am"
    },
    {
      "value": "05:30:00",
      "text": "5:30 am"
    },
    {
      "value": "06:00:00",
      "text": "6:00 am"
    },
    {
      "value": "06:30:00",
      "text": "6:30 am"
    },
    {
      "value": "07:00:00",
      "text": "7:00 am"
    },
    {
      "value": "07:30:00",
      "text": "7:30 am"
    },
    {
      "value": "08:00:00",
      "text": "8:00 am"
    },
    {
      "value": "08:30:00",
      "text": "8:30 am"
    },
    {
      "value": "09:00:00",
      "text": "9:00 am"
    },
    {
      "value": "09:30:00",
      "text": "9:30 am"
    },
    {
      "value": "10:00:00",
      "text": "10:00 am"
    },
    {
      "value": "10:30:00",
      "text": "10:30 am"
    },
    {
      "value": "11:00:00",
      "text": "11:00 am"
    },
    {
      "value": "11:30:00",
      "text": "11:30 am"
    },
    {
      "value": "12:00:00",
      "text": "12:00 pm"
    },
    {
      "value": "12:30:00",
      "text": "12:30 pm"
    },
    {
      "value": "13:00:00",
      "text": "1:00 pm"
    },
    {
      "value": "13:30:00",
      "text": "1:30 pm"
    },
    {
      "value": "14:00:00",
      "text": "2:00 pm"
    },
    {
      "value": "14:30:00",
      "text": "2:30 pm"
    },
    {
      "value": "15:00:00",
      "text": "3:00 pm"
    },
    {
      "value": "15:30:00",
      "text": "3:30 pm"
    },
    {
      "value": "16:00:00",
      "text": "4:00 pm"
    },
    {
      "value": "16:30:00",
      "text": "4:30 pm"
    },
    {
      "value": "17:00:00",
      "text": "5:00 pm"
    },
    {
      "value": "17:30:00",
      "text": "5:30 pm"
    },
    {
      "value": "18:00:00",
      "text": "6:00 pm"
    },
    {
      "value": "18:30:00",
      "text": "6:30 pm"
    },
    {
      "value": "19:00:00",
      "text": "7:00 pm"
    },
    {
      "value": "19:30:00",
      "text": "7:30 pm"
    },
    {
      "value": "20:00:00",
      "text": "8:00 pm"
    },
    {
      "value": "20:30:00",
      "text": "8:30 pm"
    },
    {
      "value": "21:00:00",
      "text": "9:00 pm"
    },
    {
      "value": "21:30:00",
      "text": "9:30 pm"
    },
    {
      "value": "22:00:00",
      "text": "10:00 pm"
    },
    {
      "value": "22:30:00",
      "text": "10:30 pm"
    },
    {
      "value": "23:00:00",
      "text": "11:00 pm"
    },
    {
      "value": "23:30:00",
      "text": "11:30 pm"
    }
  ];

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/hours/`
    });

    return res.data;
  }

  async update(clientId: number, dayId: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/hours/${dayId}/`,
        data: data
      });

      return res
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/hours/`,
        data: data
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(clientId: number, scheduleId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/hours/${scheduleId}/`
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  private hoursAsMap(hours: any[]) {
    return hours?.reduce((o: any, it) => {
      o[moment(it.weekday, 'dddd').weekday()] = it;
      return o;
    }, {});
  }

  private getNextOpen(now: any, hours: any[]) {
    let weekday = now.weekday();
    let nextOpen;

    const hoursMap = this.hoursAsMap(hours);
    for (let i = 0; i < 7; i++) {
      weekday++;
      if (weekday > 6) {
        weekday = 0;
      }
      nextOpen = hoursMap[weekday];
      if (nextOpen) {
        break;
      }
    }

    return nextOpen;
  }

  private getTodaysHours(now: any, hours: any[]) {
    const dayName = now.format('dddd');
    return hours?.find(it => it.weekday === dayName);
  }

  getLocalTimeString(tz: string) {
    return moment()
      .tz(tz)
      .format('h:mma');
  }

  private getDateAt(date: any, time: string, tz: string) {
    const parseFormat = 'MM/DD/YY HH:mm:ss';
    return moment.tz(
      `${moment(date)
        .tz(tz)
        .format('MM/DD/YY')} ${time}`,
      parseFormat,
      tz
    );
  }

  private getTodayAt(time: string, tz: string) {
    return this.getDateAt(moment(), time, tz);
  }

  isDuringBusinessHours(date: any, hours: any, tz: string) {
    const localTime = moment(date).tz(tz);
    const todaysHours = this.getTodaysHours(localTime, hours);
    if (!todaysHours) {
      return {
        closed: true,
        during: false
      };
    }

    const open = this.getDateAt(date, todaysHours?.from_hour, tz);
    const close = this.getDateAt(date, todaysHours?.to_hour, tz);
    return {
      closed: false,
      during: localTime.isBetween(open, close, undefined, '[)')
    };
  }

  getCurrentStatus(hours: any[], tz: string) {
    const displayFormat = 'h:mma';
    const localTime = moment().tz(tz);
    const todaysHours = this.getTodaysHours(localTime, hours);
    const nextOpen = this.getNextOpen(localTime, hours);
    const isTomorrow =
      nextOpen &&
      localTime
        .clone()
        .add(1, 'day')
        .format('dddd') === nextOpen.weekday;
    const isClosedForDay = !todaysHours;
    let text;
    let isOpen = false;

    if (todaysHours) {
      const open = this.getTodayAt(todaysHours?.from_hour, tz);
      const close = this.getTodayAt(todaysHours?.to_hour, tz);
      isOpen = localTime.isBetween(open, close);
      const willOpen = localTime.isBefore(open);
      text = isOpen
        ? `Open, Closes at ${close.format(displayFormat)}`
        : willOpen
        ? `Closed, Opens at ${open.format(displayFormat)}`
        : `Closed, Opens ${
            isTomorrow ? 'Tomorrow' : nextOpen?.weekday
          } at ${this.getTodayAt(nextOpen?.from_hour, tz).format(
            displayFormat
          )}`;
    }

    if (isClosedForDay && nextOpen) {
      text = `Closed Today, Opens ${
        isTomorrow ? 'Tomorrow' : nextOpen.weekday
      } at ${this.getTodayAt(nextOpen.from_hour, tz).format(displayFormat)}`;
    }

    return {
      todaysHours,
      isOpen,
      text,
      isClosedForDay,
      nextOpen
    };
  }
}

const clientHoursService = new ClientHoursService();
export default clientHoursService;
