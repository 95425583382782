import { http } from '../core';

class SignedIframeService {

  async getSignedIframeUrl(id: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/quick-qualifications/sign-iframe-url/`,
      data: {id}
    });
    return data;
  }
}

const signedIframeService = new SignedIframeService();
export default signedIframeService;
