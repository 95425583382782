import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

export interface ChatFilePreviewGridProps {
  files: {
    url: string;
    contentType: string;
    preview: string;
  }[];
  onRemoveClick: (index: number) => void;
}

const ChatFilePreviewGrid: React.FC<ChatFilePreviewGridProps> = ({
  files,
  onRemoveClick,
}) => {
  return (
    <IonGrid>
      <IonRow>
        {files.map((file, index) => (
          <IonCol size="4" key={index}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <IonImg
                src={file.preview}
                style={{
                  width: '100%',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <IonIcon
                icon={closeCircleOutline}
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '24px',
                  cursor: 'pointer',
                  color: 'red',
                }}
                onClick={() => onRemoveClick(index)}
              />
            </div>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ChatFilePreviewGrid;
