import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Capacitor } from "@capacitor/core";

class HapticsUtil {
  isCapacitorPlatform() {
    // Check if the app is running on a Capacitor-supported platform
    return Capacitor.isNativePlatform();
  }

  async lightImpact() {
    if (this.isCapacitorPlatform()) {
      try {
        await Haptics.impact({ style: ImpactStyle.Light });
      } catch (error) {
        console.warn("Haptics.lightImpact failed:", error);
      }
    } else {
      console.warn("Haptics.lightImpact is not supported on this platform.");
    }
  }

  async mediumImpact() {
    if (this.isCapacitorPlatform()) {
      try {
        await Haptics.impact({ style: ImpactStyle.Medium });
      } catch (error) {
        console.warn("Haptics.mediumImpact failed:", error);
      }
    } else {
      console.warn("Haptics.mediumImpact is not supported on this platform.");
    }
  }

  async heavyImpact() {
    if (this.isCapacitorPlatform()) {
      try {
        await Haptics.impact({ style: ImpactStyle.Heavy });
      } catch (error) {
        console.warn("Haptics.heavyImpact failed:", error);
      }
    } else {
      console.warn("Haptics.heavyImpact is not supported on this platform.");
    }
  }
}

const hapticsUtil = new HapticsUtil();
export default hapticsUtil;
