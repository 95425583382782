import util from './util';
import native from './native';
import config from '../config.json';
import {
  Camera,
  CameraOptions,
  PictureSourceType,
  MediaType,
  DestinationType,
} from '@ionic-native/camera';
import actionSheet from './action-sheet';
import { Filesystem } from '@capacitor/filesystem';

export interface MediaResult {
  file: File;
  url: string;
}

class Media {
  private defaultCameraOptions: CameraOptions = {
    quality: 50,
    targetWidth: 1000,
    targetHeight: 1000,
    correctOrientation: true,
    saveToPhotoAlbum: false,
    destinationType: DestinationType.FILE_URL,
  };

  private async promptForType(mediaType: MediaType): Promise<MediaResult> {
    return new Promise(async (resolve, reject) => {
      const onFileURL = async (url: string) => {
        const path =
          native.isAndroid && url.indexOf('file://') === -1
            ? `file://${url}`
            : url;
        const { data } = await Filesystem.readFile({ path });
        const contentType =
          mediaType === MediaType.PICTURE ? 'image/jpeg' : 'video/mp4';
        const dataURI = `data:${contentType};base64,${data}`;
        resolve({
          file: util.dataURItoFile(dataURI),
          url: dataURI,
        } as MediaResult);
      };

      actionSheet.show([
        {
          text: 'Camera',
          handler: () => {
            Camera.getPicture(
              Object.assign(
                {
                  mediaType,
                  sourceType: PictureSourceType.CAMERA,
                },
                this.defaultCameraOptions
              )
            )
              .then(onFileURL)
              .catch(reject);

            return true;
          },
        },
        {
          text: 'Photo Library',
          handler: () => {
            Camera.getPicture(
              Object.assign(
                {
                  mediaType,
                  sourceType: PictureSourceType.PHOTOLIBRARY,
                },
                this.defaultCameraOptions
              )
            )
              .then(onFileURL)
              .catch(reject);

            return true;
          },
        },
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            reject();
            return true;
          },
        },
      ]);
    });
  }

  async selectNativeMedia(type: MediaType) {
    return await this.promptForType(type);
  }
  async selectPhotos(): Promise<FileList> {
    return new Promise(async (resolve) => {
      util.chooseFiles(resolve, config.media.imageTypes);
    });
  }
  async selectPhoto(): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(MediaType.PICTURE);
    } else {
      return new Promise(async (resolve) => {
        util.chooseFile(resolve, config.media.imageTypes);
      });
    }
  }

  async selectEmailAttachmentFile(): Promise<MediaResult> {
    return new Promise(async (resolve) => {
      util.chooseFile(resolve, config.media.emailAttachmentTypes);
    });
  }

  async selectVideo(): Promise<MediaResult> {
    if (native.isAndroid) {
      return await this.promptForType(MediaType.VIDEO);
    } else {
      return new Promise(async (resolve) => {
        util.chooseFile(resolve, config.media.videoTypes);
      });
    }
  }
}

const media = new Media();
export default media;
