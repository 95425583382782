import React, { useState, useContext, useEffect } from 'react';
import TModal from './TModal';
import { followupConfigsService } from '../../services';
import { IonList, IonButton, IonIcon } from '@ionic/react';
import { TInputItem, ButtonGrid, TItem, TImage, TToggleItem } from '..';
import {
  chatbubble,
  time,
  image,
  trash,
  card,
  call as callIcon
} from 'ionicons/icons';
import { modalController } from '@ionic/core';
import {
  media,
  native,
  util,
  loadingIndicator,
  appNotification
} from '../../core';
import { AppContext } from '../../context/AppContext';
import GiphyPicker from '../GiphyPicker';
export interface FollowupConfigFormModalProps {
  item: any;
  onDidDismiss?: () => any;
}

const FollowupConfigFormModal: React.FC<FollowupConfigFormModalProps> = ({
  item,
  onDidDismiss
}) => {
  const { state: appState } = useContext(AppContext);
  const {
    selectedClient
  } = appState;
  const giphyEnabled = selectedClient.gif_search_enabled;
  const [config, setConfig] = useState(Object.assign({}, item));
  const [canSubmit, setCanSubmit] = useState(true);
  const [photo, setPhoto] = useState<any>();
  const [photoPreview, setPhotoPreview] = useState<any>();
  const [showGiphyPicker, setShowGiphyPicker] = useState(false);

  useEffect(() => {
    setPhotoPreview(config.media);
  }, [config]);

  const updateConfig = (key: string) => async (e: any) => {
    const val = e.detail.checked ?? e.detail.value;
    const configObj = config;
    configObj[key] = val;
    setCanSubmit(configObj.message && configObj.lead_age_minutes && !isNaN(configObj.lead_age_minutes));
    setConfig(configObj);
  };

    const resetDefaultConfigs = async () => {
      try {
        await loadingIndicator.create();
        await followupConfigsService.restoreDefaultColdLeadFollowupConfigs(
          config.client
        );
        appNotification.toast('Default followups restored successfully.', 'Restored');
        modalController.dismiss();
      } finally {
        loadingIndicator.dismiss();
      }
    };

  const saveFollowupConfig = async () => {
    try {
      await loadingIndicator.create();
      if (photo) {
        config.media = photo;
      }
      if (item.id) {
        if (config.media && typeof config.media === 'string' && config.media.indexOf('http') === 0) {
          delete config.media;
        }
        await followupConfigsService.patch(
          config.client,
          config.id,
          config
        );
      } else {
        await followupConfigsService.create(
          config.client,
          config
        );
      }

      appNotification.toast('Followup saved successfully.', 'Saved');
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  };
  const onFileSelected = async (file: any) => {
    return new Promise<void>(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoPreview(reader.result);
        setPhoto(file);
        resolve();
      });

      reader.readAsDataURL(file);
    });
  };

  const captureNative = async () => {
    try {
      const { file, url } = await media.selectPhoto();
      setPhotoPreview(url);
      setPhoto(file);
    } catch (e) {
      console.error(e);
    }
  };

  const attachImage = async () => {
    if (native.isAndroid) {
      captureNative();
    } else {
      const res = await media.selectPhoto();
      onFileSelected(res);
    }
  };

  const attachGiphy = async (e: any) => {
    const imageUrl = e.images.downsized.url;
    const dataUrl = (await util.urlToDataUrl(imageUrl)) as string;
    setPhotoPreview(imageUrl);
    setPhoto(util.dataURItoFile(dataUrl));
    setShowGiphyPicker(false);
  };

  const removeImage = (e: any) => {
    e.stopPropagation();
    setPhotoPreview(null);
    setPhoto(null);
    const configCopy = Object.assign({}, config);
    configCopy.media = '';
    setConfig(configCopy);
  };

  return (
    <TModal
      isOpen={item}
      onDidDismiss={onDidDismiss}
      title="Follow Up Configuration"
      className="followup-config-form-modal"
      footer={
        <ButtonGrid>
          <IonButton
            onClick={saveFollowupConfig}
            disabled={!canSubmit}
            color="secondary"
            expand="block"
          >
            Save
          </IonButton>
          <IonButton
            onClick={resetDefaultConfigs}
            color="danger"
            expand="block"
          >
            Reset Defaults
          </IonButton>
        </ButtonGrid>
      }
    >
      {item && (
        <IonList>
          <TInputItem
            lines="full"
            icon={chatbubble}
            label="Message"
            multiline={true}
            onChange={updateConfig('message')}
            value={config.message}
          />
          <TToggleItem
            lines="full"
            icon={callIcon}
            label="Needs Call"
            checked={config.needs_call}
            onIonChange={updateConfig('needs_call')}
          />
          <TToggleItem
            lines="full"
            icon={card}
            label="Attach VCard"
            checked={!!config.attach_vcard}
            onIonChange={updateConfig('attach_vcard')}
          />
          <TItem icon={image} wrapLabel lines="full">
            {photoPreview && <TImage src={photoPreview} alt="Attached Image" />}
            {photoPreview && (
              <IonButton
                slot="end"
                color="danger"
                fill="clear"
                onClick={removeImage}
              >
                <IonIcon slot="icon-only" icon={trash} />
              </IonButton>
            )}
            {!photo && (
              <>
                <IonButton slot="end" color="secondary" onClick={attachImage}>
                  Attach Image
                </IonButton>
                {giphyEnabled && (
                  <IonButton
                    slot="end"
                    color="secondary"
                    onClick={() => setShowGiphyPicker(true)}
                  >
                    Attach GIF
                  </IonButton>
                )}
              </>
            )}
          </TItem>
          <TInputItem
            lines="none"
            icon={time}
            type="tel"
            onChange={updateConfig('lead_age_minutes')}
            label="Lead Age Minutes"
            value={config.lead_age_minutes}
          />
        </IonList>
      )}
      {showGiphyPicker && (
        <GiphyPicker
          isOpen={true}
          onSelected={attachGiphy}
          onDidDismiss={() => setShowGiphyPicker(false)}
        />
      )}
    </TModal>
  );
};

export default FollowupConfigFormModal;
