import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useRouter } from '../hooks';
import {
  IonLabel,
  IonItem,
  IonList,
  IonText,
  IonIcon,
  IonButton,
  IonItemGroup,
  IonItemDivider,
  IonProgressBar
} from '@ionic/react';
import {
  TInputItem,
  TPage,
  TSelectItem,
  TToggleItem,
  TAvatar,
  TTextItem,
  TButtonItem,
  TItem,
  TItemGroup,
  ButtonGrid,
  HapticButton
} from '../components';
import { AppContext } from '../context/AppContext';
import { logOut, arrowUp, logoFacebook, trash, calendar, camera, closeCircle, save } from 'ionicons/icons';
import { userService, pushNotifications, facebookService } from '../services';
import {
  updates,
  appNotification,
  native,
  util,
  loadingIndicator,
  data,
  environment,
  media
} from '../core';

const SettingsPage: React.FC = () => {
  const [introVideo, setIntroVideo] = useState<any>();
  const [introVideoPreview, setIntroVideoPreview] = useState<string | null>(null);
  const [coverPhoto, setCoverPhoto] = useState<any>();
  const [coverPhotoPreview, setCoverPhotoPreview] = useState<any>();
  const { state, dispatch } = useContext(AppContext);
  const [checkingForUpdate, setCheckingForUpdate] = useState(false);
  const [installingUpdate, setInstallingUpdate] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(0);
  const [isFacebookConnected, setIsFacebookConnected] = useState<any>(false);
  const [photo, setPhoto] = useState<any>();
  const [photoPreview, setPhotoPreview] = useState<any>();
  const router = useRouter();

  const onCoverFileSelected = async (file: any) => {
    return new Promise<void>(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setCoverPhotoPreview(reader.result);
        setCoverPhoto(file);
        resolve();
      });
      reader.readAsDataURL(file);
    });
  };

  const onIntroVideoSelected = async (file: any) => {
    return new Promise<void>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        setIntroVideoPreview(reader.result as string);
        setIntroVideo(file);
        resolve();
      };
      reader.readAsDataURL(file);
    });
  };

  const saveIntroVideo = async () => {
    if (!introVideo) return;
  
    const formData = new FormData();
    formData.append('intro_video', introVideo);
  
    try {
      const data = await userService.update(formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      user['intro_video'] = data?.intro_video;
      setIntroVideo(null);
      setIntroVideoPreview(null);
      dispatch({
        type: 'setUserInfo',
        value: user,
      });
      appNotification.toast('Intro video uploaded successfully.', 'Success');
    } catch (error) {
      console.error('Failed to upload intro video', error);
      appNotification.toast('Failed to upload intro video.', 'Error');
    }
  };

  const removeIntroVideo = async () => {
    try {
      const data = await userService.update({ intro_video: null });
      user['intro_video'] = data?.intro_video;
      dispatch({
        type: 'setUserInfo',
        value: user,
      });
      setIntroVideo(null);
      setIntroVideoPreview(null);
      appNotification.toast('Intro video removed successfully.', 'Success');
    } catch (error) {
      console.error('Failed to remove intro video', error);
      appNotification.toast('Failed to remove intro video.', 'Error');
    }
  };
  
  const saveCoverPhoto = async () => {
    if (!coverPhoto) return;
  
    const formData = new FormData();
    formData.append('cover_photo', coverPhoto);
  
    try {
      const data = await userService.update(formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      user['cover_photo'] = data?.cover_photo;
      setCoverPhoto(undefined);
      setCoverPhotoPreview(undefined);
      dispatch({
        type: 'setUserInfo',
        value: user,
      });
    } catch (error) {
      console.error('Failed to upload cover photo', error);
    }
  };  

  const removeCoverPhoto = async () => {
    const data = await userService.update({ 'cover_photo': null });
    user['cover_photo'] = data?.cover_photo;
    dispatch({
      type: 'setUserInfo',
      value: user,
    });
  };
  

  const onFileSelected = async (file: any) => {
    return new Promise<void>(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoPreview(reader.result);
        setPhoto(file);
        resolve();
      });
      reader.readAsDataURL(file);
    });
  };

  const savePhoto = async () => {
    const data = await userService.update({ 'profile_image': photo });
    user['profile_image'] = data?.profile_image;
    user['profile_pic'] = data?.profile_pic;
    setPhoto(undefined);
    setPhotoPreview(undefined);
    dispatch({
      type: 'setUserInfo',
      value: user
    });
  };

  const removePhoto = async () => {
    const data = await userService.update({ 'profile_image': null });
    user['profile_image'] = data?.profile_image;
    user['profile_pic'] = data?.profile_pic;
    dispatch({
      type: 'setUserInfo',
      value: user
    });
  }

  const captureNative = async () => {
    try {
      const { file, url } = await media.selectPhoto();
      setPhotoPreview(url);
      setPhoto(file);
    } catch (e) {
      console.error(e);
    }
  };

  const attachImage = async () => {
    if (native.isAndroid) {
      captureNative();
    } else {
      const res = await media.selectPhoto();
      onFileSelected(res);
    }
  };

  const checkFacebookStatus = useCallback(async () => {
    setIsFacebookConnected(await facebookService.isLoggedIn());
  }, []);

  useEffect(() => {
    checkFacebookStatus();
  }, [checkFacebookStatus]);
  const { selectedClient } = state;
  const user = state.user || {};

  const updateAndSave = async (k: string, e: CustomEvent) => {
    let value = e.detail.checked ?? e.detail.value;
    if (k.indexOf('phone') > -1) {
      value = util.formatPhone(value);
    }

    if (user[k] === value) {
      return; //value did not change
    }

    await userService.update({ [k]: value });
    user[k] = value;
    dispatch({
      type: 'setUserInfo',
      value: user
    });
  };

  const userDetails = [
    {
      label: 'First Name',
      value: 'first_name',
      placeholder: 'First Name'
    },
    {
      label: 'Last Name',
      value: 'last_name',
      placeholder: 'Last Name'
    },
    {
      label: 'Email',
      value: 'email',
      type: 'email',
      placeholder: 'Email',
      validation: (val: any) => !!val && !!val.match(/.*@.*\./g)
    },
    {
      label: 'Cell #',
      value: 'cell_phone',
      placeholder: 'Cell Phone #',
      mask: '+1 (999) 999-9999',
      type: 'tel',
      valueModifier: /[\s_()-]+/g,
      validation: (val: any) => !!val && val.length === 12
    },
    {
      label: 'Click-to-call #',
      value: 'click_to_call_phone',
      placeholder: 'Phone',
      mask: '+1 (999) 999-9999',
      type: 'tel',
      valueModifier: /[\s_()-]+/g,
      validation: (val: any) => val === '' || (!!val && val.length === 12)
    },
    {
      label: 'Click-to-call Ext',
      value: 'click_to_call_extension',
      placeholder: 'Extension'
    },
    {
      label: 'DMS ID',
      value: 'dms_id',
      placeholder: 'ID'
    }
  ];

  const socialDetails = [
    {
      label: 'Bio',
      value: 'bio',
      placeholder: 'About Yourself',
    },
    {
      label: 'Instagram Link',
      value: 'instagram_link',
      placeholder: 'Your Instagram URL',
      validation: (val: any) => !val || isValidURL(val)
    },
    {
      label: 'Facebook Link',
      value: 'facebook_link',
      placeholder: 'Your Facebook Profile URL',
      validation: (val: any) => !val || isValidURL(val)
    },
    {
      label: 'Twitter Link',
      value: 'twitter_link',
      placeholder: 'Your Twitter Profile URL',
      validation: (val: any) => !val || isValidURL(val)
    },
    {
      label: 'LinkedIn Link',
      value: 'linkedin_link',
      placeholder: 'Your LinkedIn URL',
      validation: (val: any) => !val || isValidURL(val)
    }
  ];
  
  // Helper function to validate URLs
  const isValidURL = (val: string) => {
    try {
      const url = new URL(val);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
      return false;
    }
  };  

  const notifications = [
    {
      label: 'New Lead',
      value: 'notification_preference'
    },
    {
      label: 'New Message',
      value: 'message_notification_preference'
    },
    {
      label: 'Tags',
      value: 'tag_notification_preference'
    },
    {
      label: 'Daily Reports',
      value: 'daily_report_notifications'
    },
    {
      label: 'Monthly Reports',
      value: 'monthly_report_notifications'
    }
  ];

  const userAvatar = user.profile_pic;
  return (
    <TPage loading={false} headerTool={<></>}>
      <IonList className="no-padding" lines="full">
        <IonItem>
          <IonLabel class="ion-multiline">
            <TAvatar
              src={!!photo ? photoPreview : userAvatar}
              alt={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
              style={{ margin: 'auto', fontSize: 35, height: 100, width: 100 }}
            />
            {!photo && (
              <IonList style={{ textAlign: 'center' }}>
                <IonButton
                  fill="clear"
                  color="transparent"
                  onClick={attachImage}
                >
                  <IonIcon icon={camera} slot="start" />
                  {!!userAvatar ? 'Change' : 'Upload'}
                </IonButton>
                {user.profile_image && (
                  <IonButton
                    fill="clear"
                    color="transparent"
                    onClick={removePhoto}
                  >
                    <IonIcon icon={closeCircle} slot="start" />
                    Remove
                  </IonButton>
                )}
              </IonList>
            )}
            {!!photo && (
              <IonList style={{ textAlign: 'center' }}>
                <IonButton
                  fill="clear"
                  color="transparent"
                  onClick={savePhoto}
                >
                  <IonIcon icon={save} slot="start" />
                  Save
                </IonButton>
                <IonButton
                  fill="clear"
                  color="transparent"
                  onClick={() => setPhoto(undefined)}
                >
                  <IonIcon icon={closeCircle} slot="start" />
                  Cancel
                </IonButton>
              </IonList>
            )}
            <IonText
              style={{
                display: 'block',
                marginTop: 15,
                textAlign: 'center'
              }}
            >
              <h1>
                {user.first_name} {user.last_name}
              </h1>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://profile.tecobi.com/team/${selectedClient?.slug}/${user?.calendars_slug}`}
                >
                    {`https://profile.tecobi.com/team/${selectedClient?.slug}/${user?.calendars_slug}`}
                </a>
              </p>
            </IonText>
          </IonLabel>
        </IonItem>
        {/* Cover Photo */}
        <TItemGroup title="Cover Photo">
          <IonList style={{ textAlign: 'center' }}>
            {(coverPhotoPreview || user.cover_photo) && (
              <img
                src={!!coverPhotoPreview ? coverPhotoPreview : user.cover_photo}
                alt="Cover"
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: '1rem',
                }}
              />
            )}
            {!coverPhoto && (
              <>
                <IonButton
                  fill="clear"
                  color="primary"
                  onClick={() => util.chooseFile(onCoverFileSelected, 'image/*')}
                >
                  Upload Cover Photo
                </IonButton>
                {user.cover_photo && (
                  <IonButton fill="clear" color="danger" onClick={removeCoverPhoto}>
                    Remove Cover Photo
                  </IonButton>
                )}
              </>
            )}
            {!!coverPhoto && (
              <>
                <IonButton fill="solid" color="primary" onClick={saveCoverPhoto}>
                  Save
                </IonButton>
                <IonButton
                  fill="outline"
                  color="secondary"
                  onClick={() => {
                    setCoverPhoto(undefined);
                    setCoverPhotoPreview(undefined);
                  }}
                >
                  Cancel
                </IonButton>
              </>
            )}
          </IonList>
        </TItemGroup>
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Personal Info</IonLabel>
          </IonItemDivider>
          {userDetails.map(it => (
            <TInputItem
              key={it.value}
              label={it.label}
              value={user[it.value]}
              placeholder={it.placeholder}
              mask={it.mask}
              type={it.type as any}
              validation={it.validation}
              valueModifier={it.valueModifier}
              onBlur={e => updateAndSave(it.value, e)}
            />
          ))}
          <TSelectItem
            value={user.direct_connect_preference}
            options={[
              { value: 'cell_phone', text: 'Cell Phone #' },
              { value: 'click_to_call_number', text: 'Click-to-call #' }
            ]}
            onChange={e => updateAndSave('direct_connect_preference', e)}
            label="Inbound Calls"
          />
          <TToggleItem
            onIonChange={e => updateAndSave('press_to_connect', e)}
            label="Press 1 to Connect"
            checked={!!user.press_to_connect}
            wrapLabel
          />
          <TSelectItem
            value={user.mobile_theme}
            options={[
              { value: 'system', text: 'System' },
              { value: 'dark', text: 'Dark' },
              { value: 'light', text: 'Light' }
            ]}
            onChange={e => updateAndSave('mobile_theme', e)}
            label="Theme"
          />
          <TToggleItem
            onIonChange={e => updateAndSave('ai_grammar_correction', e)}
            checked={!!user.ai_grammar_correction}
            label="AI Grammar Correction"
            wrapLabel
          />
        </IonItemGroup>
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Social Info</IonLabel>
          </IonItemDivider>
          {socialDetails.map(it => (
            <TInputItem
              key={it.value}
              label={it.label}
              value={user[it.value]}
              placeholder={it.placeholder}
              validation={it.validation}
              onBlur={e => updateAndSave(it.value, e)}
            />
          ))}
        </IonItemGroup>
        {/* Intro Video */}
        <TItemGroup title="Salesperson Intro Video">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {(introVideoPreview || user.intro_video) && (
              <video
                controls
                style={{
                  width: '100%',
                  maxWidth: '300px',
                  borderRadius: '8px',
                  marginTop: '1rem',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <source
                  src={introVideoPreview || user.intro_video}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
            <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
              {!introVideo && (
                <>
                  <IonButton
                    fill="clear"
                    color="primary"
                    onClick={() => util.chooseFile(onIntroVideoSelected, 'video/*')}
                  >
                    Upload Intro Video
                  </IonButton>
                  {user.intro_video && (
                    <IonButton fill="clear" color="danger" onClick={removeIntroVideo}>
                      Remove Intro Video
                    </IonButton>
                  )}
                </>
              )}
              {!!introVideo && (
                <>
                  <IonButton fill="solid" color="primary" onClick={saveIntroVideo}>
                    Save
                  </IonButton>
                  <IonButton
                    fill="outline"
                    color="secondary"
                    onClick={() => {
                      setIntroVideo(null);
                      setIntroVideoPreview(null);
                    }}
                  >
                    Cancel
                  </IonButton>
                </>
              )}
            </div>
          </div>
        </TItemGroup>

        {!native.isNative && (
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel>Version</IonLabel>
            </IonItemDivider>
            <TItem label="Build">{environment.commit}</TItem>
            <TItem>
              <ButtonGrid>
                <HapticButton
                  fill="solid"
                  color="secondary"
                  expand="block"
                  onClick={async () => {
                    await loadingIndicator.create();
                    const noUpdateAvailable = () => {
                      loadingIndicator.dismiss();
                      appNotification.alertSimple(
                        'No new build available.',
                        'Updates'
                      );
                    };

                    const registrations = await navigator.serviceWorker?.getRegistrations?.();

                    if (!registrations?.length) {
                      return noUpdateAvailable();
                    }

                    const result = await Promise.all(
                      registrations?.map(async (registration: any) => {
                        try {
                          const res = (await registration.update()) as any;

                          if (res?.waiting) {
                            updates.skipWaiting(res.waiting);
                            return true;
                          } else if (res?.installing) {
                            return true;
                          }

                          return false;
                        } catch (e) {
                          console.error(e);
                        }
                      })
                    );

                    if (result.every((r: any) => !r)) {
                      noUpdateAvailable();
                    }
                  }}
                >
                  <IonIcon icon={arrowUp} slot="start" />
                  Check for New Build
                </HapticButton>
                <HapticButton
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={() => {
                    data.evictCache();
                    loadingIndicator.create();
                    updates.unregisterServiceWorker();
                  }}
                >
                  <IonIcon icon={trash} slot="start" />
                  Clear Cache & Reload
                </HapticButton>
              </ButtonGrid>
            </TItem>
          </IonItemGroup>
        )}
        {native.isNative && state.currentVersion && (
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel>Version</IonLabel>
            </IonItemDivider>
            <TTextItem label="Build" text={`${environment.commit}`} />
            <TTextItem
              label="Number"
              text={`${state.currentVersion?.buildId}${!user.is_admin ? ` (${state.currentVersion?.channel?.[0]})` : ''
                }`}
            />
            <TTextItem label="App" text={state.currentVersion?.binaryVersion} />
            {user.is_admin && (
              <TTextItem
                label="Build Channel"
                text={state.currentVersion?.channel}
              />
            )}
            {user.is_admin && (
              <TTextItem
                label="Updates Channel"
                text={state.updateConfig?.channel}
              />
            )}
            {installingUpdate && (
              <IonProgressBar color="secondary" value={updateProgress} />
            )}
            {user.is_admin && (
              <TButtonItem
                color="secondary"
                onClick={async () => {
                  setCheckingForUpdate(true);
                  const available = await updates.isUpdateAvailable();
                  setCheckingForUpdate(false);
                  if (available) {
                    setInstallingUpdate(true);
                    await updates.update(i => setUpdateProgress(i ?? 0 / 100));
                    await updates.reload()
                  } else {
                    appNotification.alertSimple(
                      'No new build available.',
                      'Updates'
                    );
                  }
                }}
              >
                <IonIcon icon={arrowUp} slot="start" />
                {checkingForUpdate
                  ? 'Checking...'
                  : installingUpdate
                    ? 'Installing...'
                    : 'Check for New Build'}
              </TButtonItem>
            )}
          </IonItemGroup>
        )}
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Notifications</IonLabel>
          </IonItemDivider>
          {notifications.map(it => (
            <TSelectItem
              key={it.value}
              value={user[it.value]}
              options={[
                { value: 'none', text: 'None' },
                { value: 'email', text: 'Email' },
                { value: 'sms', text: 'Push' },
                { value: 'both', text: 'Both' }
              ]}
              label={it.label}
              onChange={e => {
                pushNotifications.register();
                updateAndSave(it.value, e);
              }}
            />
          ))}
          <TSelectItem
            value={user.blast_notifications_enabled}
            options={[
              { value: true, text: 'On' },
              { value: false, text: 'Off' },
            ]}
            label="Blast Starting SMS"
            onChange={e => {
              updateAndSave('blast_notifications_enabled', e);
            }}
          />
        </IonItemGroup>
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Schedule</IonLabel>
          </IonItemDivider>
          <IonItem
            className="pointer"
            onClick={() => {
              router.push('/calendar-settings/', {});
            }}
            detail={true}
          >
            <IonIcon slot="start" icon={calendar} />
            <IonLabel>Calendar Settings</IonLabel>
          </IonItem>
        </IonItemGroup>
        <IonItemGroup
          style={{
            paddingBottom: 'env(safe-area-inset-bottom)'
          }}
        >
          <IonItemDivider />
          {facebookService.isFacebookSupported() && !isFacebookConnected && (
            <TButtonItem
              color="facebook"
              icon={logoFacebook}
              onClick={async () => {
                await facebookService.login();
                setIsFacebookConnected(true);
              }}
            >
              Link Facebook
            </TButtonItem>
          )}
          <IonItem
            className="pointer"
            onClick={() => dispatch({ type: 'logout' })}
          >
            <IonIcon slot="start" icon={logOut} />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonItemGroup>
      </IonList>
    </TPage>
  );
};

export default SettingsPage;
