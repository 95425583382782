import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import tecobiIcon from '../icons/tecobi_icon.svg';
import {
  IonItem,
  IonText,
  IonIcon,
  IonLabel,
  IonSkeletonText,
  IonFab,
  IonFabButton,
  IonButton,
  IonPopover,
  IonContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonFabList,
} from '@ionic/react';
import robot from '../icons/robot.svg';
import { aiRepliesService } from '../services';
import { LeadContext } from '../context/LeadContext';
import '../styles/components/TextMessageConversationFab.scss';
import { chatbubbleEllipsesOutline, closeOutline, arrowUp, flash } from 'ionicons/icons';
import {
  ChatMessageBoxContext,
  clearMessage,
} from '../context/ChatMessageBoxContext';

interface TextFabProps {
  handleScrollToTop: () => void;
}

const TextMessageConversationFab: React.FC<TextFabProps> = ({ handleScrollToTop }) => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const { appendMessage, dispatch } = useContext(ChatMessageBoxContext);
  const [replies, setReplies] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const popoverRef = useRef<any>(null);
  const [aiRepliesOpen, setAiRepliesOpen] = useState<boolean>(false);

  const append = (c: string) => {
    clearMessage();
    appendMessage(c);
    setAiRepliesOpen(false);
  };

  const lookupAiReplies = useCallback(async () => {
    if (lead) {
      setAiRepliesOpen(true);
      setLoading(true);
      const { replies } = await aiRepliesService.list(lead.client, lead.id);
      setReplies(replies);
      setLoading(false);
    }
  }, [lead]);

  useEffect(() => {
    setReplies([]);
    setLoading(true);
  }, [lead?.id]);

  const handleQuickRepliesClick = () => {
    dispatch({
      type: 'set',
      value: { showQuickReplies: true }
    })
  }

  return !lead?.client_use_ai_replies ? null : (
    <>
      <IonFab
        slot='fixed'
        className='t-fab t-ai-fab'
        vertical='bottom'
        horizontal='start'
      >
        <IonFabButton
          size='small'
          color='success'
        >
          <IonIcon icon={tecobiIcon} style={{ color: 'white' }} />
        </IonFabButton>
        <IonFabList side="end">
          <IonFabButton
            onClick={lookupAiReplies}
            color="light"
          >
            <IonIcon icon={robot} />
          </IonFabButton>
          <IonFabButton
            onClick={handleScrollToTop}
            color="light"
          >
            <IonIcon icon={arrowUp} />
          </IonFabButton>
          <IonFabButton
            onClick={handleQuickRepliesClick}
            color="light"
          >
            <IonIcon icon={flash} />
          </IonFabButton>
        </IonFabList>
      </IonFab>

      <IonPopover
        isOpen={aiRepliesOpen}
        onDidDismiss={() => setAiRepliesOpen(false)}
        arrow={false}
        ref={popoverRef}
        alignment='start'
        className='t-ai-replies-popover'
      >
        <IonCardHeader color='warning' className="ai-replies-header">
          <IonCardTitle className='ai-title'>AI Replies</IonCardTitle>
          <IonButton
            className='ai-close'
            onClick={() => popoverRef?.current?.dismiss()}
            fill='clear'
            size="default"
          >
            <IonIcon icon={closeOutline} size="large" />
          </IonButton>
        </IonCardHeader>
        <IonContent>
          <IonList>
            {loading &&
              Array.from(Array(5).keys()).map((it: any, i: number) => (
                <IonItem key={`ai-skeleton-${i}`}>
                  <IonLabel>
                    <IonSkeletonText animated style={{ width: '85%' }} />
                    <IonSkeletonText animated style={{ width: '80%' }} />
                    <IonSkeletonText animated style={{ width: '75%' }} />
                  </IonLabel>
                </IonItem>
              ))}
            {!loading && !replies?.length && <IonItem>Not available</IonItem>}
            {replies?.map((reply: string, index: number) => (
              <IonItem
                key={`ai-reply-${index}`}
                className='pointer'
                onClick={() => append(reply)}
              >
                <IonLabel className='ion-multiline ion-text-wrap'>
                  <IonText>{reply}</IonText>
                </IonLabel>
                <IonIcon icon={chatbubbleEllipsesOutline} slot='end' />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default TextMessageConversationFab;
