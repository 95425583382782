import { http } from "../core";

class RouteOneCreditApplicationService {
  async create(creditAppId: string) {
    try {
      const { data } = await http.authorizedRequest({
        method: "POST",
        url: "/routeone/credit-application/",
        data: { credit_app_id: creditAppId },
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
      return [];
    }
  }
}

const routeOneCreditApplicationService = new RouteOneCreditApplicationService();
export default routeOneCreditApplicationService;
