import React from 'react';
import { IonList, IonButton, IonItemDivider, IonIcon } from '@ionic/react';
import { modalController } from '@ionic/core';
import numeral from 'numeral';
import { TModal } from '.';
import { TTextItem } from '..';
import TItem from '../TItem';
import TExpandableItemGroup from '../TExpandableItemGroup';
import { heart } from 'ionicons/icons';
import ButtonGrid from '../ButtonGrid';
import '../../styles/components/InventoryItemModal.scss';
import { inventoryService } from '../../services';

export interface InventoryItemModalProps {
  item: any;
  isOpen?: boolean;
  clientId: number;
  clientSlug: string;
  assignedToSlug: string;
  leadId: number;
  leadHash: string;
  liked: boolean;
  onDidDismiss?: () => void;
  onLike?: (item: any) => any;
  onDislike?: (item: any) => any;
  onSend?: (url: string) => any;
  onAttachImage?: (url: string) => any;
}

const InventoryItemModal: React.FC<InventoryItemModalProps> = ({
  item,
  onDidDismiss,
  leadHash,
  leadId,
  onLike,
  onDislike,
  onSend,
  onAttachImage,
  clientSlug,
  assignedToSlug,
  liked,
  isOpen = false
}) => {
  const {
    vin,
    make,
    mileage,
    model,
    year,
    engine,
    carfax_url,
    carfax_icon_url,
    transmission_type,
    exterior_color,
    selling_price,
    trim,
    mpg_highway,
    mpg_city,
    in_stock,
    stock_number,
    image,
    option_categories,
    id
  } = item.inventory;

  const url = inventoryService.getInventoryUrl(clientSlug, leadHash, id, assignedToSlug, leadId);

  const renderFeature = (it: any, i: number) => (
    <TTextItem key={i} text={it.description} />
  );

  return (
    <TModal
      title="Inventory Details"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      footer={
        onLike || onDislike ? (
          <ButtonGrid columns={[2, 3, 7]}>
            <IonButton
              color={liked ? 'danger' : 'primary'}
              onClick={async () => {
                if (liked) {
                  onDislike?.(item.inventory);
                } else {
                  onLike?.(item.inventory);
                }
                modalController.dismiss();
              }}
            >
              <IonIcon icon={heart} />
            </IonButton>
            {onAttachImage && image && (
              <IonButton
                expand="block"
                onClick={(e: any) => {
                  e.preventDefault();
                  onAttachImage(image);
                  modalController.dismiss();
                }}
              >
                Send Photo
              </IonButton>
            )}
            {onSend && carfax_url && (
              <IonButton
                expand="block"
                onClick={() => onSend(carfax_url)}
              >
                Send Carfax
              </IonButton>
            )}
            {onSend && (
              <IonButton
                expand="block"
                color="secondary"
                onClick={() => onSend(url)}
              >
                Send Link
              </IonButton>
            )}
          </ButtonGrid>
        ) : (
          undefined
        )
      }
      className="inventory-item-modal"
    >
      <IonList lines="full">
        <TItem>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/assets/car-placeholder.jpg";
            }}
            src={image}
            alt={vin}
          />
        </TItem>
        {carfax_url && (
          <TItem
            label="Carfax Report"
            href={carfax_url}
            detail={false}
            target="_blank"
          >
            <img
              style={{width: '80px', height: 'auto', background: '#fff', padding: '2px'}}
              src={carfax_icon_url}
              alt="Show me the Carfax"
            />
          </TItem>
        )}
        <IonItemDivider sticky>General</IonItemDivider>
        <TTextItem label="Year" labelPlacement="stacked" text={year} />
        <TTextItem label="Make" labelPlacement="stacked" text={make} />
        <TTextItem label="Model" labelPlacement="stacked" text={model} />
        <TTextItem label="Trim" labelPlacement="stacked" text={trim} />
        <TTextItem label="VIN" labelPlacement="stacked" text={vin} />
        <TTextItem
          label="Mileage"
          labelPlacement="stacked"
          text={numeral(mileage).format('0,0')}
        />
        <TTextItem
          label="Exterior Color"
          labelPlacement="stacked"
          text={exterior_color}
        />
        <TTextItem label="Engine" labelPlacement="stacked" text={engine} />
        <TTextItem
          label="Transmission"
          labelPlacement="stacked"
          text={transmission_type}
        />
        <TTextItem
          label="MPG (City/Highway)"
          labelPlacement="stacked"
          text={`${mpg_city}mpg/${mpg_highway}mpg`}
        />
        <TTextItem
          label="Selling Price"
          labelPlacement="stacked"
          text={inventoryService.getSellingPrice(selling_price)}
        />
        <TTextItem
          label="Stock Number"
          labelPlacement="stacked"
          text={stock_number}
        />
        <TTextItem
          label="Status"
          labelPlacement="stacked"
          text={in_stock ? 'In Stock' : 'Not in Stock'}
        />
        <IonItemDivider sticky>Features</IonItemDivider>
        <TExpandableItemGroup title="Exterior">
          {option_categories?.Exterior?.map(renderFeature)}
        </TExpandableItemGroup>
        <TExpandableItemGroup title="Interior">
          {option_categories?.Interior?.map(renderFeature)}
        </TExpandableItemGroup>
        <TExpandableItemGroup title="Safety">
          {option_categories?.Safety?.map(renderFeature)}
        </TExpandableItemGroup>
        <TExpandableItemGroup title="Mechanical">
          {option_categories?.Mechanical?.map(renderFeature)}
        </TExpandableItemGroup>
      </IonList>
    </TModal>
  );
};

export default InventoryItemModal;
